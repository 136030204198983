
.ticket-display-header-body{
	min-width: 645px;
}
.ticket-display-header-body .top_bar-wraper {
    /*position: fixed;*/
    /*width: calc(100% - 425px);*/
    background-color: #fff;
    z-index: 99;
    left: 75px;
    padding: 10px 20px 10px 20px;    
    border-bottom: 1px solid #ddd;
    padding-left:  35px;
}
.Ticket-Left-Box {
    border-left: 1px solid #ddd;
}

.ticket-display-header-body .top_bar-wraper .d-flex .col-item {
	display: flex;
	align-items: ;
	align-items: center;
	gap: 10px;
}

.ticket-display-header-body .top_bar-wraper .d-flex .col-item p {
	font-size: 16px;
	color: #000;
	font-weight: 400;
	margin: 0;
}
.ticket-display-header-body .top_bar-wraper .d-flex .col-item p a {
/*	color: #1899be;*/
	text-decoration: none;
}

.ticket-display-header-body .top_bar-wraper .vpn_issue h4 {
	line-height: normal;
	font-size: 20px;
	font-weight: 500;
	color: #4c4f56;
	display:inline;
	margin-left: 10px;
	word-wrap: break-word;
}

.ticket-display-header-body .top_bar-wraper .d-flex .col-item.right ul {
	display: flex;
	margin: 0px;
	padding: 0;
}
.ticket-display-header-body .top_bar-wraper .d-flex .col-item.right ul li {
	list-style: none;
}
.ticket-display-header-body .top_bar-wraper .d-flex .col-item.right ul li svg {
	width: 30px;
	height: 20px;
}
.ticket-display-header-body .top_bar-wraper .d-flex .col-item.right ul li a {
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 0 10px;
	font-size: 16px;
	color: #000;
	gap: 10px;
	border-right: 1px solid #929292;
	line-height: normal;
}

.ticket-display-header-body .top_bar-wraper .d-flex .col-item.right ul li.note a {
border-right: 0px;
}


.ticket-display-header-body .top_bar-wraper .d-flex .col-item.right ul li .icon img {
	max-width: 25px;
}

.top_bar-wraper .d-flex {
	justify-content: space-between;
}

.ticket-display-header-body.top_bar-wraper  .d-flex .col-item p .color.green {
	width: 12px;
	height: 12px;
	display: inline-block;
	background-color: #009600;
	border-radius: 2px;
}
.ticket-display-header-body .top_bar-wraper .d-flex .col-item p.pipeline {
	color: #929292;
	position: relative;
	top: -1px;
}

.tab_links{
	display: flex;
	flex-direction:  row;
}
.tab_links ul {
	margin: 0;
	padding: 0;
	display: flex;
	list-style: none;
	flex-direction:  row;
}
.tab_links ul li{
	margin-left: 20px;
}
.tab_links ul li a {
	padding: 10px 10px ;
	text-decoration: none;
	color: #000;
	display: block;
	border-bottom: 2px solid transparent;
	font-size: 17px;
	transition: 0.5s all ease-in-out;
}
.tab_links ul li a.active, .tab_links ul li a:hover {
	border-bottom: 2px solid #1f98bc;
	color: #1f98bc;
}

.tab_links ul li.dots a{
	border-bottom: 0px;
}
.tab_links ul li.dots a img {
	max-width: 25px;

}

.tab_links ul li.dots a:hover{
	border-bottom: 0;
}

.Ticket-Left-Box .content table {
	width: 100%;
}

.Ticket-Left-Box .content table td input {
	border-radius: 5px;
	box-shadow: none;
	border: 1px solid #ccc;
	color: #000;
}
.attachment .d-flex {
	gap: 10px;
}
.attachment .d-flex .icon img {
	max-width: 22px;
}
.Ticket-Left-Box .content table .color.green {
	width: 12px;
	height: 12px;
	display: inline-block;
	background-color: #009600;
	border-radius: 2px;
}

.Ticket-Left-Box .content table .gray.circle {
	width: 10px;
	height: 10px;
	display: inline-block;
	background-color: #ddd;
	border-radius: 10px;
}
.work img {
	max-width: 25px;
}
.Ticket-Left-Box .content table td {
	padding: 8px 5px !important;
	font-size: 16px;
	color: #000;
}
.Ticket-Left-Box .content table td strong {
	font-weight: 600;
}

.table_2 td a {
	text-align: right;
	display: inline-block;
	color: #1899be;
	text-decoration: none;
}
.table_2 td b {
	color: #979797;
	font-weight: normal;
	margin: 0 5px;
}

.table_2 td:nth-child(2) {
	text-align: right;
}
.table_2 td {
	border-bottom: 1px solid #e8e8e8;
}
.requester_details {
	padding-top: 30px;
}
.requester_details .title {
	margin: 0 0 20px;
}

.requester_details .title h3 {
	color: #000;
	font-size: 16px;
	text-transform: uppercase;
}

.requester_details .user_name h3 {
	font-size: 18px;
	font-weight: 600;
	color: #000;
}

.requester_details .email_address a {
	color: #000;
	text-decoration: none;
	font-size: 16px;
	font-family:Helvetica, sans-seriff;
}
.requester_details .view_full_details a {
	color: #1899be;
	text-decoration: none;
	display: flex;
	align-items: center;
	gap: 10px;
}
.requester_details .profile.d-flex {
	gap: 15px;
}

.Ticket-Page .ticket-middle-box {
    /*margin-top: 0px !important;*/
}

/* commentline */
.inbox-message{
  background-color: white;
}
.inbox-message ul {
    padding: 0;
    margin: 0;
}
.inbox-message ul li {
    list-style: none;
    position: relative;
    padding: 15px 20px;
  border-bottom: 1px solid #e8eef1;
}
.inbox-message ul li:hover, .inbox-message ul li:focus {
    background: #eff6f9;
}
.inbox-message .message-avatar {
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
}
.message-avatar img {
    display: inline-block;
    width: 54px;
    height: 54px;
    border-radius: 50%;
}
.inbox-message .message-body {
    margin-left: 85px;
    font-size: 15px;
    color:#62748F;
}
.message-body-heading h5 {
    font-weight: 600;
  display:inline-block;
    color:#62748F;
    margin: 0 0 7px 0;
    padding: 0;
}
.message-body h5 span {
    border-radius: 50px;
    line-height: 14px;
    font-size: 12px;
    color: #fff;
    font-style: normal;
    padding: 4px 10px;
    margin-left: 5px;
    margin-top: -5px;
}
.message-body h5 span.unread{
  background:#07b107; 
}
.message-body h5 span.important{
  background:#dd2027; 
}
.message-body h5 span.pending{
  background:#2196f3; 
}
.message-body-heading span {
    float: right;
    color:#62748F;
    font-size: 14px;
}
.messages-inbox .message-body p {
    margin: 0;
    padding: 0;
    line-height: 27px;
    font-size: 15px;
}

a:hover{
    text-decoration:none;
}
