.call-window{
  position: absolute;
  bottom:5px;
  left:80px;
  min-height: 500px;
  min-width: 325px;
  width:325px;
  border: 1px solid black;  
  background-color: rgb(30, 47, 82);
  border-radius:25px;
  z-index:1000 !important;
}
.incoming-call-notification{
  position: absolute;
  background-color: rgb(49, 68, 105);
  border-radius: 5px;
  height: 135px;
  z-index:100;
  width:320px;
  display: block;
  top:-65px;
}

.call-window-right-box{
  border-radius:25px;
  background-color: white;
  border: 1px solid white;
  /*rgb(49, 68, 105)*/
  background-color: rgb(30, 47, 82);
  border: 1px solid rgb(30, 47, 82);
  width:calc(100% - 325px);
  min-height:550px;  
  left:325px;
  position:absolute;
  display: block;
}
.call-window-button{
  position: absolute;
  left: 0px;
  bottom: 0px;
  margin-top: 10px;
  margin-left:5px;
  padding-left:20px;
  padding-right:20px;
  padding-top:5px;
  padding-bottom:5px;
  background-color: #19528a;
  font-size: 12px;
  color:white;
  font-family: sans-serif;
  font-weight: 600;
  border-radius: 10px;
  box-shadow:0;
  /*25, 99, 44*/
}

.call-window-input::placeholder {
  color: white;
  opacity: 1; /* Firefox */
}
.call-window-input:after{
  background: url("../../../img/back-button.png") no-repeat scroll 70px 70px;;
}
.call-window-input{  
  padding-top:12px;
  outline:none;
  background-color: rgb(49, 68, 105);/*rgb(33, 70, 100);*/
  border: 1px solid red;
  border: 0;
  color: white !important;
  /*width:100%;*/
  font-weight:bolder;
  font-size:16px;
  font-weight: 600;
  border-radius: 10px;
  padding-left:25px;
  vertical-align:text-top;
  display:inline-block;
  width:200px;
  height:40px;  
  border-style: none !important;
}
.call-window-short-input{
  padding-top:12px;
  outline:none;
  background-color: rgb(49, 68, 105);/*rgb(33, 70, 100);*/
  border: 1px solid red;
  border: 0;
  color: white;
  border-style: none !important;
  /*width:100%;*/
  font-weight:bolder;
  font-size:35px !important;
  padding-left: 10px;
  border-radius: 10px;
  vertical-align:text-top;
  display:inline;
  height:40px;  
}
.call-window-input-wrapper{
  border-radius: 10px;
  background-color: rgb(49, 68, 105);
  margin-left:20px;
  margin-right:20px;
  margin-top: 20px;
  width:250px;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
}
.call-window-input-dropdown{
  width:100%;
  color:white;
  position:absolute;
}
.call-window-status{
  top:0%;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 30px;
  width:50%;
  background-color: rgb(30, 47, 82);
  box-sizing: border-box;
  position: absolute;
}
.call-window-status span{  
  color:white;
  font-weight:500;
  padding:10px;
  border-radius:10px;
}
.call-window-status div{
  color:#008000;
  background-color: #008000;
  background: radial-gradient(circle at 65% 15%, white 1px, aqua 3%, green 60%, aqua 100%);  
  border-radius:50%;
  height:10px;
  width:10px;
  display:inline-block;
  right:9px;
  top:9px;
  position: absolute;
}

.call-window-dial-pad{
  display: flex;
  flex-direction: row;   
  height:300px;
  top:75px;  
  width:350px;
  margin-left:15px;
  /*margin-top:20px;*/
  /*border:1px solid rgb(49, 68, 105);*/
}
.call-window-dial-pad div{
  color: white;
}
.call-window-dial-pad  table{
  border: none;
  width:100%;
  margin-top:20px;
  border-collapse: collapse
}

.call-window-dial-pad  table, tr, td{
  border-collapse: collapse;
  /*border: none; This is showing bottom border black line in contact list page*/
}
.call-window-digit{
  width:25%;  
  font-weight:bolder;
  font-size:x-large;
  display: inline-block;
  padding-left:35px;
  color:white;
  cursor: pointer;
}

.call-window-digit img{
  height:15px;
  width:15px;
}
.call-window-digit:hover{
  background: rgb(49, 68, 105);
}
.call-window-call-button{
  color:white;
  margin-left:45%;
  border-radius: 50%; 
}

.call-window ::-webkit-scrollbar {
    height: 12px;
    width: 20px;
    background-color: rgb(49, 68, 105);    
}

.call-window ::-webkit-scrollbar-thumb {
    background: #1b2b4a;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}


.call-window-list ::-webkit-scrollbar-corner {
    background: rgb(49, 68, 105);
}
.call-window-list{
  display: flex;
  flex-direction: column;  
  margin-top: 10px;
  overflow-y: scroll;
  max-height: 450px;
  width:325px;
}
.class-window-list-item:hover{
 background: rgb(49, 68, 105);

}
.class-window-list-item{
  color: white;
  padding: 5px;
  margin-left:5px;
  margin-bottom:5px;
  border-radius: 5px;
  padding-left: 15px;
  padding-top:15px;
  padding-bottom: 15px;
  font-weight:bolder;
  font-size:small;
  width:calc(100% - 10px);
  cursor: pointer;
}

 .loader {
  border: 8px solid rgb(49, 68, 105);
  border-top: 8px solid rgb(30, 47, 82);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-left:120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.call-window-buy-buton{
  margin-top:10px;
  margin-left:15%;
  height:50px;
  padding-top:5px;
  padding-bottom:5px;
  padding-left:10px;
  padding-right:10px;
  color:white;
  width:175px;
  background-color:rgb(49, 68, 105);
  text-align:center;
  position:absolute;
}
.call-window-buy-message{
  color:white;
  /*margin-top:40%;*/
  margin-left:10%;
  font-weight:bolder;
  /*position: absolute;*/
  font-size:12px;
  width:300px;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}
.class-in-progress-table{
  bottom:12px;
  position:absolute;
}
.green-colour{
  background-color: green;
}
.call-in-progress-icons{
  margin-top:50px;
  display: flex;
  width:100%;
  position: absolute;
}
.call-in-progress-icon{
  width:20%;
  padding:35px;
}
/*.call-in-progress-icon:hover{
  background: rgb(49, 68, 105);
}*/
.call-in-progress-mute{
  border-radius: 50%;  
  width:50px;
  width:50px;
  background: rgb(49, 68, 105);
  cursor: pointer;
}
.call-in-progress-mute:hover{
  transform: scale(1.2)
}

.call-inprogress-icons{
  cursor:pointer;
  padding:10px;
  border-radius:50%;
  height:35px;
  width:35px;
  display: inline-block;
  color:white;
  position:relative;
}
.call-inprogress-icons:hover{
  background: rgb(49, 68, 105);
}
.call-window-create-ticket-summary{
  margin-top:2px;
  
  margin-right:10px;
  height:36px;
  width:100%;
  background-color: rgb(49, 68, 105);
  color: white;
  border-radius:5px;
  border:none;
  outline:none;
}
.call-window-create-ticket-dropdown-content{
  padding:5px;
  color:white;
  font-size: 16px;
  font-weight: 600;
}
.call-window-create-ticket-dropdown-menu{
  width:100%;
  position:absolute;
  max-height:200px;
  overflow-y: auto;
  background-color: rgb(49, 68, 105);
  -webkit-box-shadow: 0 0 2px 2px rgb(49, 68, 105);
        box-shadow: 0 0 2px 2px rgb(49, 68, 105); 
  z-index:1000 !important;
  border: 1px solid rgb(49, 68, 105); 
  cursor:pointer; 
  display:none;
}
.call-window-create-ticket-description{
  height:150px;
  border-radius:5px;
  padding:10px;
  margin-top:1px;
  max-height:200px;
  width:100%;
  border:none;
  outline:none;
  border: 1px solid black;
  background-color: rgb(49, 68, 105);
  color: white;
  font-weight: 500;
  font-size: 16px;
  font-family: Helvetica,sans-serif;
  overflow-y: auto;

}
.gradient
{
/*    height:300px;
    width:300px;
    border:1px solid black;
    font-size:30px;
*/    background: linear-gradient(130deg, rgb(30, 47, 82), rgb(49, 68, 105), rgb(65, 122, 232));
    /*background-size: 200% 200%;*/
    -webkit-animation: Animation 5s ease ;
    -moz-animation: Animation 5s ease ;
    animation: Animation 5s ease ;
}

@-webkit-keyframes Animation {
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}
@-moz-keyframes Animation {
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}
@keyframes Animation { 
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}

.phone-number-purchase-message{
  position: absolute;
  top:30%;
  left:10px;
}
.call-window-transfer-dropdown-menu{
  max-height:300px;
  overflow-y: auto;
  border:1px solid rgb(49, 68, 105);
  width:250px;
  background-color: rgb(49, 68, 105);
  z-index:100;
}
.call-window-transfer-dropdown-content{
  display: block;
  cursor:pointer;
  padding:10px;
  padding-left:20px;
  text-decoration: none;
  color:white;
  font-weight: bolder;

}
.call-window-transfer-dropdown-menu::-webkit-scrollbar {
    height: 12px;
    width: 20px;
    background: ;
}


.call-window-profile-picture{
  background-color: white;
}

.call-window-contact-image{
height:20px;
width:20px;
border-radius: 50%;
}
.call-window-contact:hover{
  background-color:rgb(49, 68, 105);
}
.call-window-footer{
    font-size:10px;
    bottom:20px;
    position: absolute;
    width:100%; 
    display:flex; 
    justify-content: space-around;
}
.call-window-recent-calls{
  max-height: 300px;
  overflow-y: auto;
}
@media only screen and (max-height: 500px) {
.call-window{
  position: absolute;
  bottom:5px;
  left:80px;
  min-height: 400px;
  min-width: 325px;
  width:325px;
  border: 1px solid black;  
  background-color: rgb(30, 47, 82);
  border-radius:25px;
  z-index:1000 !important;
}
.call-window-dial-pad{
  display: flex;
  flex-direction: row;   
  height:200px;
  top:75px;  
  width:350px;
  margin-left:15px;
  /*margin-top:20px;*/
  /*border:1px solid rgb(49, 68, 105);*/
 }
}
@media only screen and (max-height: 400px) {
.call-window{
  position: absolute;
  bottom:5px;
  left:80px;
  min-height: 350px;
  min-width: 325px;
  width:325px;
  border: 1px solid black;  
  background-color: rgb(30, 47, 82);
  border-radius:25px;
  z-index:1000 !important;
}
.incoming-call-notification{
  top:-10px;
}
.call-window-dial-pad{
  display: flex;
  flex-direction: row;   
  /*height:165px;*/
  top:75px;  
  width:350px;
  margin-left:15px;
  /*margin-top:20px;*/
  /*border:1px solid rgb(49, 68, 105);*/
}
.call-window-input{
  padding: 0px;
}
.call-window-input-wrapper{
  height: 40px;
}
.call-window-footer{
  bottom:0px;
}
}