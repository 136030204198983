
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
	position: fixed;
  margin: 0;
  height: 100vh;
  width:100vw;
  font-size:14px;
  text-rendering: optimizeLegibility; 
  place-items: center;  
  background: white;
  min-height:100vh;
  max-width:100vw;
   -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: normal;  
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica, Roboto,Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
	background: linear-gradient(to bottom right, rgba(245, 242, 240, .8901960784) 0, #fff 100%);
}

.another-good-font-family{
	/*font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"*/
	font-family: Roboto;
}
a:focus{
	cursor:pointer;
}
.topnav{
	width:75px;
	right: 0px;
	height:65px;
	top:0%;
	padding: 2px;
	background-color: white;
	position:fixed;
	z-index: 100 !important;
	display:flex;
	align-items: center;	
}
.sidenav{
	top:0px;
	left:0px;
	width:75px;
	height:100vh;
	max-height:100%;
	position:absolute;
  	font-size: 16px;
  	color: white;
  	font-weight:bolder;
  	font-family: BogleWeb,MyriadPro,Helvetica Neue,Helvetica,Arial,sans-serif;
  	/*background-color: #282c34;*/
  	background-color:rgb(30, 47, 82);
  	vertical-align: middle;
}

.header-body{
	top:0px;
	right: 75px;
	left:75px;
	width:calc(100% - 150px);
	height:75px;
	position: absolute;
}
.main-body{
	top:65px;
	left:75px;
	width:calc(100vw - 75px);
	height:calc(100vh - 75px);
	position:absolute;	
	background-color: white;
	/*
		Enabling this is displaying vertical scroll bar for main body
		overflow-x: hidden;
	*/
}
.contact-body{
	width: 100%;
	height:100%;
}
.call-route-body{
	width: 100%;
	height:100%;
}
.call-body{
	width: 100%;
	height:100%;
	display:flex;	
}
.company-body{
	width: 100%;
	height:100%;
}
.contact-group-body{
	width: 100%;
	height:100%;
}
.ticket-body{
	width: 100%;
	height:100%;
	display:flex;
}
.user-body{
		width: 100%;
	height:100%;
}
/*.call-window{
	position:fixed;
	bottom:0;
	right:0;
	height:25%;
	width:25%;
	z-index:1;
}*/
.chat-window{
	position:fixed;
	bottom:0;
	left:0;
	height:25%;
	width:25%;
	z-index:1;
}
/*input[type='checkbox']{
	outline: 1px solid blue;
}*/