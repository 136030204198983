@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');

::selection{
  background: #1a75ff;
  color: #fff;
}

.login-wrapper {
    /*padding: 10px;
    display: inline-block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    min-width: 400px;
    min-height: 500px;
    width:400px;
    height:600px;
    margin: auto;
    
    border-radius:20px;*/

  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  width:100vw;


}

.login-wrapper .logo {
  /*padding: 0 30px 30px;*/
}

.login-wrapper .logo img {
  display: block;
  margin: auto;
  max-width: 80%;
  width:125px;
}


.inside-wrapper{
  border-radius: 100px;
  border: 1px solid lightgrey;
  webkit-box-shadow:0 0.0625rem 0.125rem 0.0625rem rgba(0,0,0,.14902), 0 -0.0625rem 0.125rem 0 rgba(0,0,0,.10196);
  box-shadow: 0 0.0625rem 0.125rem 0.0625rem rgba(0,0,0,.14902), 0 -0.0625rem 0.125rem 0 rgba(0,0,0,.10196) ;
  position: relative;
  max-width: 430px;
  width: 100%;
  background: #fff;
  padding: 34px;
  border-radius: 20px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.2);
}
.inside-wrapper .title-text{
  display: flex;
  width: 100%;
}
.inside-wrapper .title{
  width: 50%;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  transition: all 0.6s cubic-bezier(0.68,-0.55,0.265,1.55);
}
.inside-wrapper .slide-controls{
  position: relative;
  display: flex;
  height: 50px;
  width: 100%;
  overflow: hidden;
  margin: 30px 0 10px 0;
  justify-content: space-between;
  border: 1px solid lightgrey;
  border-radius: 15px;
}
.slide-controls .slide{
  height: 100%;
  width: 100%;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
  z-index: 1;
  transition: all 0.6s ease;
}
.slide-controls label.signup{
  color: #000;
}
.slide-controls .slider-tab{
  position: absolute;
  height: 100%;
  width: 50%;
  left: 0;
  z-index: 0;
  border-radius: 15px;
  background: -webkit-linear-gradient(left,rgb(76, 120, 237),rgb(76, 120, 237),rgb(76, 120, 237)
, #0073e6);
  transition: all 0.6s cubic-bezier(0.68,-0.55,0.265,1.55);
}
.slide-controls input[type="radio"]{
  display: none;
}
#signup:checked ~ .slider-tab{
  left: 50%;
}
#signup:checked ~ label.signup{
  color: #fff;
  cursor: default;
  user-select: none;
}
#signup:checked ~ label.login{
  color: #000;
}
#login:checked ~ label.signup{
  color: #000;
}
#login:checked ~ label.login{
  cursor: default;
  user-select: none;
}
.inside-wrapper .form-container{
  width: 100%;
  overflow: hidden;
}
.form-container .form-inner{
  display: flex;
  width: 100%;
}
.form-container .form-inner form {
  width: 100%;
  transition: all 0.6s cubic-bezier(0.68,-0.55,0.265,1.55);
}
.form-inner form .field{
  height: 30px;
  width: 100%;
  margin-top: 20px;
}
.form-inner form .field input {
  width: 100%;
  outline: none;
  padding-left: 0px;
  border-radius: 0px;
  border: 0px solid lightgrey;
  border-bottom-width: 2px;
  font-size: 15px;
  transition: all 0.3s ease;
  padding:5px;
}
.form-inner form .field input:focus{
  border-color: #1a75ff;
  /* box-shadow: inset 0 0 3px #fb6aae; */
}
.form-inner form .field input::placeholder{
  color: black;
  transition: all 0.3s ease;
}
form .field input:focus::placeholder{
  color: #1a75ff;
}
.form-inner form .pass-link{
  margin-top: 5px;
}
.form-inner form .signup-link{
  text-align: center;
  margin-top: 30px;
}
.form-inner form .pass-link a,
.form-inner form .signup-link a{
  color: #1a75ff;
  text-decoration: none;
}
.form-inner form .pass-link a:hover,
.form-inner form .signup-link a:hover{
  text-decoration: underline;
}
form .btn{
  height: 50px;
  width: 100%;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}
form .btn .btn-layer{
  height: 100%;
  width: 100%;
  position: absolute;
  left: -100%;
  border-radius: 15px;
  transition: all 0.4s ease;;
}
form .btn:hover .btn-layer{
  left: 0;
}
form .btn input[type="submit"]{
  height: 100%;
  width: 100%;
  z-index: 1;
  position: relative;
  color: #fff;
  padding-left: 0;
  border-radius: 15px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
    background: -webkit-linear-gradient(right,#003366,#004080,#0059b3
, #0073e6);
}
.reset-pass-title{
  display: none;
}

/*form.reset, form.signup {
  display: none;
}*/
.toaster{
  border: 1px solid #3A9EA5 !important;
  border-radius: 5px !important;
  background: #F0F9FA !important;
  position:fixed;
  top:1px;
  left:75px;
  min-width:calc(100vw - 75px);
  display: flex;
  padding:10px;
  justify-content: center;
  font-size:17px;
  font-weight:500;
  z-index: 100 !important;
}
